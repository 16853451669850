footer {
  font-weight: bold;;
  padding: 5px;
  font-size: small;
  text-align: center;
  color: lightgray;
  background-color: black;
  position: fixed;
  bottom: 0;
  width: 100%;
}

li {
  margin-right: 10px;
}

.navbar{
  background-color: white;
  box-shadow: 1px 1px 5px;
}

.navbar-brand {
  margin-left: 20px;
}

.navbar-toggler {
  margin-right: 20px;
}

.email {
  margin-top: 100px;
}

.email p {
  font-size: 1.5rem;
}

.email h1 {
  font-size: 2.5rem;
}


.contact-container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.contact-cards {
  margin-top: 30px;
}

.card-body ul li a{
  color: #df4759;
}

.contact-cards li {
  font-weight: bold;
}
.event-finder {
  margin-top: 100px;
  padding-top: 100px;
  padding-bottom: 150px;
  color: white;

}

.events{
  color: black;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  margin-top: 30px;
}


.about-us{
  margin-top: 100px;
  margin-bottom: 100px;
}

.about-us p{
  font-size: 1.15rem;
}

.about-title {
  margin-bottom: 30px;
}

.baloons {

  background-image: url("./balloonpic.jpg");
  height: 300px;
  background-size: cover;
  background-position: top;
  border-radius: 25px;
  margin-bottom: 100px;
  
}

.baloon-description {
  margin-top: 100px;
  margin-bottom: 100px;
}

.about-row-1 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.img-thumbnail {
  border: none;
}


.banner {

  background-image: 
  linear-gradient(to right, rgba(204, 0, 0, 0.8), rgba(255, 103, 103, 0.5)),
  url("./Banner.jpg");
  height: 850px;
  background-size: cover;
  background-position: top;
  
}

.banner h1 {
  font-size: 5rem;
}

.banner p {
  font-size: 1.5rem;
}

.tagline { 
  text-align: left;
  color: white;
  padding-top: 240px;
  padding-bottom: 100px;
  padding-right: 20%;
}